import React from "react";
import TabBar from "./TabBar";
import Select from 'react-select';
import 'animate.css';
import { FaCrown } from "react-icons/fa"
import { url } from "../../config";
import Error from "../utility/Error";
import notify from "../../utils/notify"
import { Link } from "react-router-dom";
import Login from "../utility/Login";
import { connect } from "react-redux";

class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      textChannel: [],
      voiceChannel: [],
      color: "",
      roles: [],
      saveFromModel: false,
      error: false,
      message: "",
      t27: false,
      autoplay: false,
      toggleDj: false,
      selectedText: [],
      selectedVoice: [],
      selectedRoles: [],
      isPremium: false,
      guild: { name: "", icon: "" }
    }
  }

  async handleSubmit(e) {
    e.preventDefault()
    let res = await fetch(`${url}/guilds/${window.location.pathname.split("/")[2]}/update`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(this.state),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    );
    if (res.status === 200) {
      let data = await res.json();

      switch (data.mode) {
        case 'ERROR':
          {
            notify(data?.message, 'error')
            break;
          }
        case 'SUCCESS':
          {
            notify(data?.message)
            this.handleResetForm()
            break;
          }
        default:
          {

          }

      }
    }
  }

  handleToggleButton(e) {
    this.setState({ [e.target.name]: !this.state[e.target.name] })

  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleFormUpdate(value) {
    this.setState({ saveFromModel: value ?? true })
  }
  handleTextChannel(selectedText) {
    this.setState({ selectedText, saveFromModel: true })

  }

  handleVoiceChannel(selectedVoice) {
    this.setState({ selectedVoice, saveFromModel: true })

  }
  handleDjRoles(selectedRoles) {
    this.setState({ selectedRoles, saveFromModel: true })
  }

  handleResetForm() {
    this.setState({ saveFromModel: false })
  }

  async componentDidMount() {
    let res = await fetch(`${url}/guilds/${window.location.pathname.split("/")[2]}`, {
      method: "POST",
      credentials: "include",
      Accept: "application/json",

      "Content-Type": "application/json",
    },
    );
    if (res.status === 200) {
      let data = await res.json();
      switch (data.mode) {
        case "ERROR":
          {
            this.setState({ error: true, message: data.message })
            break;
          }
        case 'SUCCESS':
          {
            let { t27, autoplay, djroles, prefix, text, voice, toggleDj, color, isPremium } = data.data;
            text = data.textChannels.filter(x => text.includes(x.value))
            voice = data.voiceChannels.filter(x => voice.includes(x.value))
            djroles = data.roles.filter(x => djroles.includes(x.value))

            this.setState({
              t27, autoplay, selectedRoles: djroles, prefix, color, isPremium, toggleDj, selectedText: text, selectedVoice: voice,
              textChannel: data.textChannels, voiceChannel: data.voiceChannels, roles: data.roles, guild: data.guild
            })
            break;
          }
        default:
          {

          }
      }
    }




  }


  render() {
    if (!this.props.user) return <Login />
    if (this.state.error) return <Error message={this.state.message} />
    return <>
      <TabBar guild={this.state.guild} />

      <form onSubmit={(e) => { this.handleSubmit(e) }} onReset={() => { this.handleResetForm() }} onChange={() => { this.handleFormUpdate() }} className="mt-[80px] ml-auto lg:ml-[350px] md:ml-[350px] md:mx-10  rounded-lg">
        <div className="bg-neutral-800 shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
          <h1 className="text-white text-xl font-bold mb-4">General Settings</h1>
          <hr className=" border-neutral-700 mb-4 mx-4" />

          <div className="-mx-3 md:flex mb-6">

            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-white text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Prefix
              </label>
              <input name="prefix" onChange={(e) => { this.handleChange(e) }} defaultValue={this.state.prefix}
                className="rounded-lg apperence-nonee focus:shadow-lg  focus:shadow-cyan-500 font-bold block w-full bg-neutral-900 appearance-none text-white   py-3 px-4 mb-3"
                type="text"
              />
            </div>
            <div className="md:w-1/2 px-3">
              <label
                className="  tracking-wide text-white text-xs font-bold mb-2 flex flex-row"
                htmlFor="grid-last-name"
              >
                <p className="mr-1"> Embed Color </p> {!this.state.isPremium ? <Link to='/premium'><FaCrown size={15} color={"yellow"} /></Link> : null}
              </label>
              <input disabled={!this.state.isPremium} name="color" onChange={(e) => { this.handleChange(e) }} defaultValue={this.state.color}
                type="color" class={` ${!this.state.isPremium ? 'cursor-not-allowed' : ''} rounded-lg bg-neutral-900 w-full h-12 px-4 py-2 leading-tight text-gray-700 border border-gray-200  appearance-none focus:outline-none focus:bg-white focus:border-gray-500`} />   </div>
          </div>

          <div className="-mx-3 md:flex mb-6 flex flex-col">
            <h1 className="text-white text-xl font-bold mb-4 flex flex-row"> <p className="mr-2">Music Settings </p> {!this.state.isPremium ? <Link to='/premium'><FaCrown size={25} color={"yellow"} /></Link> : null}</h1>
            <hr className=" border-neutral-700 mb-4 mx-4" />
            <div className="-mx-3 md:flex mb-6">


              <div className="md:w-1/2 px-3 mb-6 md:mb-0 flex flex-row">


                <label class="relative inline-flex items-center cursor-pointer ">
                  <input disabled={!this.state.isPremium} name="t27" onChange={(e) => { this.handleToggleButton(e) }} type="checkbox" className={`sr-only peer`} checked={this.state.t27} />
                  <div class={` ${!this.state.isPremium ? 'cursor-not-allowed' : ''} w-11 h-6 appearance-none bg-neutral-700   rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cyan-500`}></div>
                  <span class=" text-xl font-medium text-white ml-4">24/7 Mode </span>
                </label>

              </div>
              <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label class="relative inline-flex items-center cursor-pointer">
                  <input disabled={!this.state.isPremium} name="autoplay" onChange={(e) => { this.handleToggleButton(e) }} type="checkbox" class="sr-only peer" checked={this.state.autoplay} />
                  <div class={` ${!this.state.isPremium ? 'cursor-not-allowed' : ''} w-11 h-6 appearance-none bg-neutral-700   rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cyan-500`}></div>
                  <span class=" text-xl font-medium text-white ml-4">Autoplay </span>

                </label>

              </div>










            </div>
          </div>




          <h1 className="text-white text-xl font-bold mb-4">Channel Settings</h1>
          <hr className=" border-neutral-700 mb-4 mx-4" />

          <div className="-mx-3 md:flex mb-6">

            <div className="md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-white text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Text Channels
              </label>


              <Select isMulti={true} isSearchable={true} className="bg-neutral-900 rounded-lg"
                placeholder="Select Text Channels"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: '#171717'
                  }),
                }}

                value={this.state.selectedText}
                onChange={(e) => { this.handleTextChannel(e) }}
                options={this.state.textChannel}
              />
            </div>

            <div className="md:w-1/2 px-3">
              <label
                className="block  tracking-wide text-white text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Voice Channels
              </label>
              <Select isMulti={true} isSearchable={true}
                placeholder="Select Voice Channels"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: '#171717'
                  }),
                }}

                value={this.state.selectedVoice}
                onChange={(e) => { this.handleVoiceChannel(e) }}
                options={this.state.voiceChannel}
              />
            </div>
          </div>





          <div className="-mx-3 md:flex mb-6 flex flex-col">
            <h1 className="text-white text-xl font-bold mb-4">DJ Settings</h1>
            <hr className=" border-neutral-700 mb-4 mx-4" />
            <div className="-mx-3 md:flex mb-6">


              <div className="md:w-1/2 px-3 mb-6 md:mb-0 pt-4">

                <label class="relative inline-flex items-center cursor-pointer ">
                  <input type="checkbox" name="toggleDj" checked={this.state.toggleDj} onChange={(e) => { this.handleToggleButton(e) }} class="sr-only peer" />
                  <div class="w-11 h-6 appearance-none bg-neutral-700   rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cyan-500"></div>
                  <span class=" text-xl font-medium text-white ml-4">Toggle DJ mode </span>

                </label>

              </div>
              <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block  tracking-wide text-white text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  DJ Roles
                </label>
                <Select isMulti={true} isSearchable={true}
                  placeholder="Select DJ Roles"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: '#171717'
                    }),
                  }}

                  value={this.state.selectedRoles}
                  onChange={(e) => { this.handleDjRoles(e) }}
                  options={this.state.roles}
                />
              </div>

            </div>
          </div>





        </div>


        {this.state.saveFromModel ?
          <div
            id="model"
            className=" lg:bottom-0 bottom-[-60px]  right-0 fixed w-30  lg:mx-[250px] animate__animated animate__backInUp "
          >
            <div className="relative lg:p-4 lg:w-full  lg:h-full h-[170px]  p-4">
              <div className="relative bg-neutral-900 rounded-lg  dark:bg-gray-700">
                <div className="flex">
                  <h3 className="lg:text-xl text-sm font-semibold text-white m-auto pl-2">
                    Careful — you have unsaved changes!{" "}
                  </h3>
                  <div className="flex items-center p-2 space-x-2 rounded-b ">
                    <button
                      type="reset"
                      className="text-white  rounded-lg  text-sm font-medium px-5 py-2.5focus:z-10"
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
        }













      </form>






    </>
  }
}


function mapStateToProps(state) {

  return {
    user: state.user,
    guilds: state.guilds
  };
}



export default connect(mapStateToProps)(Settings);