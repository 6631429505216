import React from "react";
import TabBar from "./TabBar"
import MusicPlayer from "./MusicPlayer";
import { url } from "../../config";
import io from "socket.io-client"
import notify from "../../utils/notify";
import { connect } from "react-redux";
import { setConnection, setPlayer, setQueue, setTrack,setPosition, setPaused } from "../../store/musicSlice";
import NoVoice from "./noVoice";
import {socket} from "./Player"
import {CiMenuFries} from "react-icons/ci"
import Login from "../utility/Login";

class Search extends React.Component {

  constructor() {
    super();
    this.state = {
      query: '',
      tracks: [],
      message: '',
      isNavOpen:false
       
    }
  }
 
  toggleNavBar(){
    this.setState({isNavOpen:!this.state.isNavOpen})
}



  componentDidMount() {
      let connectionOptions = {
        "force new connection": true,
        "reconnectionAttempts": "Infinity",
        "timeout": 10000,
        "transports": ["websocket"],
        query: { serverID: window.location.pathname.split("/")[2] }
      };

      if(socket){
        this.ws = socket
      } else {
      this.ws = new io(url, connectionOptions);
      }
      this.ws.on("VOICE_CONNECTION", (data) => {
        this.props.dispatch(setConnection(data));
      })

      this.ws?.on("PLAYER_UPDATE", (data) => {
           
        switch (data?.mode) {
            case "ALL":
                {
                    const { track, queue, volume, isPaused, loop } = data.player;
                    this.props.dispatch(setPlayer({ track, queue, volume, isPaused, loop }))
                    break;
                }
            case "TRACK_START":
            {
                const {track} = data;
                this.props.dispatch(setTrack(track))
                this.props.dispatch(setPaused(false))
                break;
            }
            case "QUEUE_UPDATE":
            {
                const {queue} = data;
                this.props.dispatch(setQueue(queue));
                break;
            }
            case "RESET_PLAYER":
              {
                  this.props.dispatch(setPlayer({ track: null, queue: [], isPaused: false, loop: false, volume: 100 }))
                  break;
              }
       
            case "PLAYER_POSITION":
              {
                  this.props.dispatch(setPosition(data.position));
                  break;
              }
              default:
              {

              }
      
       }




    })





      this.ws?.on("SEARCH_RESULT", (data) => {
        console.log(data)
        switch (data.mode) {
          case "TRACK_LOADED":
            {
              this.setState({ tracks: data.tracks, message: "" })
              break;
            }
          case "PLAYLIST_LOADED":
            {
              this.setState({ message: data.message, tracks: [] });
              break;
            }
       
          case "NO_MATCHES":
            {
              this.setState({ message: data.message, tracks: [] });
              break;
            }
            default:
            {

            }
        }
      })
    
  }

  handleTrack(track) {
    this.ws?.emit("PLAY_TRACK", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, track }))
    notify(`Added song into queue`)
  }
  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.query) return;
    this.ws?.emit("SEARCH_QUERY", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, query: this.state.query }))
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    if(!this.props.user) return <Login/>
    return <>

{!this.props.music.isConnected ?
                <NoVoice />
                :
                null
            }
      <div className="grid grid-rows-3 grid-cols-6 h-full spotify-circular">

      <TabBar toggle={this.state.isNavOpen} toggleNavBar={()=>{this.toggleNavBar()}} id={window.location.pathname.split("/")[2]} />
          <main className="col-span-5 row-span-3 overflow-auto mt-8">
          <header className="px-6 py-4 mb-6 bg-neutral-900 flex items-center justify-between sticky top-0 z-10">
                        <div className="flex items-center">
                            <button onClick={()=>{this.toggleNavBar()}} className="h-8 w-8  lg:md:hidden  text-white flex">
                          
                            <CiMenuFries size={30} color="white"/>
                            </button>
                        </div>
                        <div>
                            <button className="text-xs text-white bg-gray-500 rounded-full p-px pr-3">
                                <img alt="avatar"
                                    src={this.props.user?.avatar}
                                    className="w-7 h-7 inline-block rounded-full mr-2"
                                />
                                {this.props.user?.username || "No User"}
                            </button>
                        </div>
                    </header>
                

          <div className="max-w-md mx-auto rounded-lg  ">
            <form onSubmit={(e) => { this.handleSubmit(e) }} className="pl-1 relative flex items-center text-white w-full h-12 rounded-lg focus-within:shadow-lg bg-neutral-800 overflow-hidden">
              <input defaultValue={this.state.query} name='query' onChange={(e) => { this.handleChange(e) }}
                className="peer h-full w-full placeholder-white bg-neutral-800 outline-none text-lg  text-bold text-white pr-2"
                type="text"
                id="search"
              />

              <button type="submit" className="grid place-items-center h-full w-12 text-white ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>

            </form>
          </div>



          <div className=" md:mx-[200px] mt-10 shadow overflow-hidden sm:rounded-md">
            <ul>
              {this.state.tracks.map((track, i) => {
                return <li className="mt-2" key={i}>
                  <div className="flex items-center justify-between bg-neutral-800 px-4 py-4 sm:px-6">
                    <div className="flex items-center">
                      <div className="ml-3">
                        <p className="text-lg font-medium text-white text-justify">{track.title.slice(0, 60)}</p>
                        <p className="text-sm text-white">{track.author}</p>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <button onClick={() => { this.handleTrack(track) }} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">
                        Add to Queue
                      </button>
                    </div>
                  </div>
                </li>
              })

              }

              {this.state.message && <li>
                <div className="flex items-center mt-4 justify-between bg-neutral-800 px-4 py-4 sm:px-6">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <p className="text-lg font-medium text-white">{this.state.message}</p>
                    </div>
                  </div>
                </div>
              </li>
              }
            </ul>
          </div>



        </main>

        <MusicPlayer ws={this.ws}/>
      </div>
    </>
  }
}

function mapStateToProps(state) {

  return {
      user: state.user,
       music: state.music
  };
}



export default connect(mapStateToProps)(Search);