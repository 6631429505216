import React from "react"
import NavBar from "../layout/NavBar";
import { connect } from "react-redux";
import validate, { fetchGuilds } from "../../utils/validate";
import "../../scrollBar.css";
import Server from "./Server";
import Login from "../utility/Login";

class Dashboard extends React.Component {
  
  componentDidMount() {
    validate(this);
    fetchGuilds(this);
  }

  render() {
    if(!this.props.user) return <Login/>
      
    return <>
      <NavBar />
      <section class="text-gray-200 body-font  bg-neutral-900 items-center text-center ">
        <div class="container px-5 py-10 mx-auto inline-block">
          <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-200">Select a server</h1>
          </div>
        </div>
      </section>



      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto ">
          <div className="flex flex-wrap md:w-auto w-full  -m-4 ">
            {this.props.guilds?.mutualGuilds.map((guild) => {
              return <Server data={guild} mutual={true} />
            })
            }

            {this.props.guilds?.userGuilds.map((guild) => {

              return <Server data={guild} mutual={false} />
            })
            }


          </div>
        </div>
      </section>





    </>
  }
}

function mapStateToProps(state) {

  return {
    user: state.user,
    guilds: state.guilds
  };
}



export default connect(mapStateToProps)(Dashboard);