import React from "react"
import { connect } from "react-redux";
import prettyMilliseconds from "pretty-ms";
import { setPosition, setVolume } from "../../store/musicSlice";
import { AiOutlinePlayCircle } from "react-icons/ai"
import notify from "../../utils/notify";
import { SlVolume2, SlVolumeOff } from "react-icons/sl"
function convert(ms) {
    return prettyMilliseconds(ms, { colonNotation: true, secondsDecimalDigits: 0 });
}
class MusicPlayer extends React.Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            id: ""
        }
    }

    componentWillUnmount() {
        this.props.dispatch(setPosition(0))
        clearInterval(this.state.id)

    }


    componentDidMount() {
        let x = setInterval(() => {
            if (this.props.music.position <= this.props.music.track?.length && !this.props.music.isPaused && this.props.music.track) {
                let value = (this.props.music.position * 100 / this.props.music.track?.length)
                this.props.dispatch(setPosition(this.props.music.position + 1000))
                this.setState({ value })
            }
        }, 1000);
        this.setState({ id: x })













    }



    handleShuffle() {
        if (this.props.music.queue.length === 0) {
            return notify("Queue is empty", "error")
        }

        this.props.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "SHUFFLE" }))
        notify("Shuffled Queue!")
    }

    handlePrevious() {
        this.props.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "PREVIOUS" }))
        notify("Playing previous song!")
    }

    handleSkip() {
        this.props.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "SKIP" }))
        notify("Skipped")

    }
    handlePause() {
        this.props.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "PAUSE" }))

    }

    handleLoop() {
        this.props.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "LOOP" }))
        let msg = (!this.props.music.loop ? 'Looping queue' : 'Loop disabled')
        notify(msg)

    }
    handleSeek(e) {
        this.setState({ value: e.target.value })
        let position = (e.target.value * this.props.music.track?.length) / 100;
        this.props.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "SEEK", position }))

    }

    handleVolume(e) {
        this.props.dispatch(setVolume(e.target.value));
        this.props.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "VOLUME", volume: e.target.value }))

    }

    render() {
        return <>
            <footer className="bg-gray-200  p-2 w-full z-40 fixed md:bottom-0  bottom-1">
                <div className="flex items-center">
                    <img
                        className="h-14 w-14 mr-4 flex-shrink-0"
                        src={this.props.music.track?.image || "https://github.com/parasop/resource/blob/main/melody.png?raw=true"}
                        alt="https://github.com/parasop/resource/blob/main/melody.png?raw=true"
                    />
                    <div className="mr-4">
                        <div className="text-sm text-white text-line-clamp-1 font-bold">
                            {this.props.music.track?.title || "No Song Playing"}
                        </div>
                        <div className="text-xs text-gray-100 text-line-clamp-1">
                            <span>{this.props.music.track?.author}</span>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button className="text-green-200 p-2">
                            <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                                <path d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z" />
                            </svg>
                        </button>

                    </div>
                </div>
                <div>
                    {this.props.music.track &&
                        <>
                            <div className="flex items-center justify-center mb-3">
                                <button name="shuffle" onClick={() => { this.handleShuffle() }} className="w-5 h-5 text-gray-100 mr-6">
                                    <svg
                                        className="fill-current w-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M6.59 12.83L4.4 15c-.58.58-1.59 1-2.4 1H0v-2h2c.29 0 .8-.2 1-.41l2.17-2.18 1.42 1.42zM16 4V1l4 4-4 4V6h-2c-.29 0-.8.2-1 .41l-2.17 2.18L9.4 7.17 11.6 5c.58-.58 1.59-1 2.41-1h2zm0 10v-3l4 4-4 4v-3h-2c-.82 0-1.83-.42-2.41-1l-8.6-8.59C2.8 6.21 2.3 6 2 6H0V4h2c.82 0 1.83.42 2.41 1l8.6 8.59c.2.2.7.41.99.41h2z" />
                                    </svg>
                                </button>
                                <button name="previous" onClick={() => { this.handlePrevious() }} className="w-5 h-5 text-gray-100 mr-6">
                                    <svg
                                        className="fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M4 5h3v10H4V5zm12 0v10l-9-5 9-5z" />
                                    </svg>
                                </button>
                                {!this.props.music.isPaused ?

                                    <button onClick={() => { this.handlePause() }} className="w-8 h-8 border border-gray-300 rounded-full flex text-gray-100 mr-6">
                                        <svg
                                            className="fill-current h-5 w-5 m-auto"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z" />
                                        </svg>
                                    </button>
                                    :
                                    <button onClick={() => { this.handlePause() }} className="w-8 h-8  rounded-full flex text-gray-100 mr-6">
                                        <AiOutlinePlayCircle size={30} />
                                    </button>

                                }
                                <button onClick={() => { this.handleSkip() }} className="w-5 h-5 text-gray-100 mr-6">
                                    <svg
                                        className="fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M13 5h3v10h-3V5zM4 5l9 5-9 5V5z" />
                                    </svg>
                                </button>
                                <button onClick={() => { this.handleLoop() }} className="w-5 h-5 text-gray-100">
                                    <svg
                                        className="fill-current w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M10 3v2a5 5 0 0 0-3.54 8.54l-1.41 1.41A7 7 0 0 1 10 3zm4.95 2.05A7 7 0 0 1 10 17v-2a5 5 0 0 0 3.54-8.54l1.41-1.41zM10 20l-4-4 4-4v8zm0-12V0l4 4-4 4z" />
                                    </svg>
                                </button>
                                <div className="w-auto   fixed  right-0  mr-4 flex flex-row">
                                    {this.props.music.volume === 0 ?
                                        <SlVolumeOff size={20} color={"white"} className="mr-1" />
                                        :
                                        <SlVolume2 size={20} color={"white"} className="mr-1" />

                                    }

                                    <input
                                        type="range"
                                        className="transparent mt-2 h-1.5    w-[100px] cursor-pointer thumb:bg-green-600 appearance-none rounded-lg border-transparent accent-green-500 bg-green-500"
                                        onChange={(e) => { this.handleVolume(e) }}
                                        value={this.props.music.volume}
                                        minLength={0}
                                        maxLength={100}

                                    />

                                </div>    
                                </div>
                            <div className="flex items-center">
                                <span className="text-lg text-gray-100  mr-2 font-bold">{convert(this.props.music.position || 0)}</span>

                                <div className="w-full h-1 bg-gray-300 rounded-full">
                                    <div className="h-1 bg-green-500 rounded-full w-full mx-4 " style={{ width: `${this.state.value}%` }}></div>

                                </div>
                                <input
                                    type="range" 
                                    className="absolute lg:md:w-full w-[45px] mx-4 px-8 h-1  appearance-none rounded-full bg-transparent thumb:rounded-full thumb:w-5 thumb:h-5 thumb:bg-white thumb:border thumb:border-gray-300 thumb:shadow-lg"
                                    value={this.state.value}
                                    minLength={0}
                                    maxLength={100}
                                    onChange={((e) => { this.handleSeek(e) })}
                                />




                                <span className="text-lg ml-2 font-bold text-gray-100 ">{convert(this.props.music.track?.length || 0)}</span>
                            </div>
                        </>
                    }
                </div>
            </footer>







        </>










    }
}

function mapStateToProps(state) {

    return {
        music: state.music
    };
}



export default connect(mapStateToProps)(MusicPlayer);