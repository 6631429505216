import Footer from "./Components/layout/Footer";
import HomePage from "./Components/layout/HomePage";
import NavBar from "./Components/layout/NavBar";
function App() {
  return (
    <div className="bg-neutral-900">
      <NavBar/>
      <HomePage/>
      <Footer/>
    </div>
  );
}

export default App;
