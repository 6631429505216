import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from './Components/dashboard/dashboard';
import Player from './Components/Player/Player';
import ServerAdmin from './Components/dashboard/ServerAdmin.';
import Settings from './Components/dashboard/Settings';
import MusicRoom from './Components/Player/MusicRoom';
import Search from './Components/Player/Search';
import CustomPlaylist from './Components/Player/CustomPlaylist';
import PlaylistSongs from './Components/Player/PlaylistSongs';
import Premium from './Components/Premium';
import Fail from './Components/Payment/Fail';
import Success from './Components/Payment/Success';
import Error from './Components/utility/Error';
import Privacy from './Components/layout/Privacy';
import Terms from './Components/layout/Terms';
import Invite from './Components/Invite';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "music-room",
    element: <MusicRoom />
  },
  {
    path: "music-room/:id",
    element: <Player />
  },
  {
    path: "music-room/:id/search",
    element: <Search />
  },
  {
    path: "music-room/:id/custom",
    element: <CustomPlaylist />
  },
  {
    path: "music-room/:id/custom/:playlistId",
    element: <PlaylistSongs />
  },
  
  {
    path: "/dashboard",
    element: <Dashboard />
  },
  {
    path: "/dashboard/:id",
    element: <ServerAdmin />
  },
  {
    path: "/dashboard/:id/settings",
    element: <Settings />
  },
  {
    path:"/premium",
    element:<Premium/>
  },
  {
    path:"/invite",
    element:<Invite/>
  },
  {
    path:"payment-success",
    element:<Success/>
  },
  {
    path:"payment-fail",
    element:<Fail/>
  },
  {
    path:"error",
    element: <Error/>
  },
  {path: "/privacy",
  element:<Privacy/>
  },
  {
    path:'/terms',
    element:<Terms/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <RouterProvider router={router} />
    </Provider> </React.StrictMode>
);

