import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
      set(state, action) {
           return state = action.payload
        },
    remove(state, action) {
            return state = null
        },
    },
});

export const { set, remove } = userSlice.actions;
export default userSlice.reducer;