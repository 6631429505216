
import { Link } from "react-router-dom"
function NoPremium() {
    return (
        <div
            tabIndex={-1}
            className=" inset-0 bg-red-50  h-screen w-screen  flex bg-opacity-50 justify-center z-50 fixed pt-[200px]  top-0 right-0 left-0 "
        >
            <div className="relative w-full max-w-md h-full md:h-auto">
                <div className="relative bg-[#221A27] rounded-lg shadow ">
                    <div className="p-6 text-center">
                        <h3 className="mb-5 text-lg font-normal text-white">
                            You need to Upgrade to server Premium
                        </h3>
                        <button type="button" class="text-white bg-cyan-500 hover:bg-cyan-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"><Link to={'/premium'}>Subscribe Now </Link></button>
                        </div>
                </div>
            </div>
        </div>


    )
}

export default NoPremium