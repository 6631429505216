import React from "react"
import NavBar from "./layout/NavBar"
import { ServerPremium ,CustomBot,LifeTime} from "../config"
import { url} from "../config"
import Faq from "./Payment/Faq"
import Footer from "./layout/Footer"
class Premium extends React.Component {
  

    handleServerPremium(){
      window.open(`${url}/payment/server-premium`, "_self")

    }
    handleCustomBot(){
      window.open(`${url}/payment/custom-bot`, "_self")
    }

    handleLifetimePlan(){
      window.open(`${url}/payment/lifetime-plan`, "_self")

    }
  
   
    render() {
        return <>
            <div>
            <NavBar />
            <section className="relative z-20 overflow-hidden bg-neutral-900 pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-2">
                        <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                            <h2 className="text-white mb-4 text-3xl font-bold sm:text-4xl md:text-[40px]">
                                Our Premium Plans
                            </h2>
                            <p className="text-white text-base">
                                Unlock exclusive features and take your music to the next level
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto">
                    <div className="shadow p-5 rounded-lg border border-green-400 bg-neutral-800">
                        <p className="uppercase text-sm font-medium text-white">Server Premium</p>
                        <p className="mt-4 text-3xl text-white font-medium">
                            $1.99 <span className="text-xl">/month</span>

                        </p>
                        <p className="mt-4 font-medium text-white">Premium server, premium beats - upgrade today!</p>
                        <div className="mt-8">
                            <ul className="grid grid-cols-1 gap-2">
                                {ServerPremium.map((x) => {
                                    return <li className="inline-flex items-center text-white">
                                        <svg
                                            className="w-4 h-4 mr-2 fill-current text-green-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM371.8 211.8l-128 128C238.3 345.3 231.2 348 224 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L224 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C382.7 183.1 382.7 200.9 371.8 211.8z" />
                                        </svg>
                                        {x}
                                    </li>
                                })
                                }
                            </ul>
                        </div>
                        <div className="mt-8">
                            <button onClick={()=>{this.handleServerPremium()}} className="bg-green-400 hover:bg-green-500 px-3 py-2 rounded-lg w-full text-white">
                                Subscribe
                            </button>
                        </div>
                    </div>


                    <div className="shadow p-5 rounded-lg border border-green-400 bg-neutral-800">
                        <p className="uppercase text-sm font-medium text-white">Custom Bot</p>
                        <p className="mt-4 text-3xl text-white font-medium">
                            $3.99 <span className="text-xl">/month</span>

                        </p>
                        <p className="mt-4 font-medium text-white">Customize your beats with our premium custom bot - upgrade today!</p>
                        <div className="mt-8">
                            <ul className="grid grid-cols-1 gap-2">
                                {CustomBot.map((x) => {
                                    return <li className="inline-flex items-center text-white">
                                        <svg
                                            className="w-4 h-4 mr-2 fill-current text-green-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM371.8 211.8l-128 128C238.3 345.3 231.2 348 224 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L224 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C382.7 183.1 382.7 200.9 371.8 211.8z" />
                                        </svg>
                                        {x}
                                    </li>
                                })
                                }
                            </ul>
                        </div>
                        <div className="mt-8">
                            <button  onClick={()=>{this.handleCustomBot()}}
                            className="bg-green-400 hover:bg-green-500 px-3 py-2 rounded-lg w-full text-white">
                                Subscribe
                            </button>
                        </div>
                    </div>



                </div>
            </section>



    <div className=" m-auto px-6 py-20 md:px-12 lg:px-20 bg-neutral-900">
      <div className="mt-12 m-auto -space-y-4 items-center justify-center md:flex md:space-y-0 md:-space-x-4 xl:w-10/12">
        <div className="relative z-10 -mx-4 group md:w-6/12 md:mx-0 lg:w-5/12">
          <div
            aria-hidden="true"
            className="absolute top-0 w-full h-full rounded-2xl bg-neutral-800 border border-green-400 shadow-xl transition duration-500 "
          />
          <div className="relative p-6 space-y-6 lg:p-8">
            <h3 className="text-3xl text-white font-semibold text-center">
              Lifetime Custom Bot
            </h3>
            <div>
              <div className="relative flex justify-around">
                <div className="flex items-end">
                  <span className="text-8xl text-white font-bold leading-0">
                    50$
                  </span>
                  <div className="pb-2">
                    <span className="block text-2xl text-gray-700 font-bold">
                    </span>
                  <span className="block text-xl text-purple-500 font-bold">
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ul
              className="w-max space-y-4 py-6 m-auto text-white"
            >
              <li className="space-x-2">
                <span className="text-green-500 font-semibold">✓</span>
                <span>One time Payment</span>
              </li>
              <li className="space-x-2">
                <span className="text-green-500 font-semibold text-justify">✓</span>
                <span>Lifetime premium = ultimate music journey</span>
              </li>
              <li className="space-x-2">
                <span className="text-green-500 font-semibold">✓</span>
                <span>Most Valueable Plan</span>
              </li>
            </ul>
            <button onClick={()=>{this.handleLifetimePlan()}}
              className="block w-full py-3 px-6 text-center rounded-xl transition bg-green-600 hover:bg-green-700 active:bg-green-800 focus:bg-green-600"
            >
              <span className="text-white font-semibold">Subscribe</span>
            </button>
          </div>
        </div>
        <div className="relative group md:w-6/12 lg:w-7/12">
          <div
            aria-hidden="true"
            className="absolute top-0 w-full h-full rounded-2xl bg-neutral-800 shadow-lg transition duration-500 group-hover:scale-105"
          />
          <div className="relative p-6 pt-16 md:p-8 md:pl-12 md:rounded-r-2xl lg:pl-20 lg:p-16">
            <ul className="space-y-4 py-6 text-white">
            {LifeTime.map((x)=>{ 
              return <li className="space-x-2">
                <span className="text-green-500 font-semibold">✓</span>
                <span>{x}</span>
              </li>
                })
                }
             </ul>
            <p className="text-white font-bold text-xl">
            Personalized music experience, lifetime access</p>
           
          </div>
        </div>
      </div>
    </div>
  
    </div>




                <Faq/>
                <Footer/>
            </>



    }
}

export default Premium


