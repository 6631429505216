import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
} from "@material-tailwind/react";
//import validate from "../../utils/validate"
import { connect } from "react-redux";
import { url } from "../../config";
import validate from "../../utils/validate"
import melody from "../../assets/melody.png"
import { Link } from "react-router-dom"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { GiQueenCrown } from "react-icons/gi"
const navList = (
  <ul className=" text-white mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
    <Typography
      as="li"
      variant="small"
      color="blue-gray"
      className="p-1 font-normal"
    >
      <Link to="/dashboard" className="flex items-center text-base font-bold">
        Dashboard
      </Link>
    </Typography>
    <Typography
      as="li"
      variant="small"
      color="blue-gray"
      className="p-1 font-normal"
    >
      <Link to="/music-room" className="flex items-center text-base font-bold">
        Music Rooms
      </Link>
    </Typography>
    <Typography
      as="li"
      variant="small"
      color="blue-gray"
      className="p-1 font-normal"
    >
      <Link to="/invite" className="flex items-center text-base font-bold">
        Invite
      </Link>
    </Typography>
    <Typography
      as="li"
      variant="small"
      color="blue-gray"
      className="p-1 font-normal"
    >
      <Link to="/premium" href="#" className="flex items-center text-base font-bold bg-amber-500 text-amber-900 p-1 rounded-lg">
        <GiQueenCrown size={30} />
        Premium
      </Link>
    </Typography>
  
  </ul>
);

class NavBar extends React.Component {
  constructor() {
    super();

    this.state = {
      isNavBarOpen: false,
      toggle: false,
      isDropDownOpen: false
    };
  }

  loginWithDiscord() {

    window.open(`${url}/auth`, "_self")
  }

  handleLogout() {
    window.open(`${url}/auth/logout`, "_self")

  }
  toggleModel() {
    this.setState({ toggle: !this.state.toggle })
  }

  toggleDropDown() {
    this.setState({ isDropDownOpen: !this.state.isDropDownOpen })
  }

  billingPortal(){
    window.open(`${url}/payment/create-customer-portal-session`, "_self")

  }

  async componentDidMount() {

    validate(this)

    window.addEventListener("resize", () => window.innerWidth >= 960 && this.setState({ isNavBarOpen: false }));
  }

  render() {

    return (
      <>

        {this.state.toggle ? (
          <div
            id="popup-modal"
            tabIndex={-1}
            className="flex justify-center  mt-[150px] ml-auto lg:ml-[150px] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 p-4 md:inset-0 h-modal md:h-full"
          >
            <div className="relative w-full max-w-md h-full md:h-auto">
              <div className="relative bg-neutral-800 rounded-lg shadow ">
                <div className="p-6 text-center">
                  <AiOutlineInfoCircle
                    className="text-center m-auto"
                    style={{ fontSize: "50px", color: "white" }}
                  />
                  <h3 className="mb-5 text-lg font-normal text-white">
                    Are you sure you want to logout?
                  </h3>
                  <button
                    type="button"
                    onClick={() => this.handleLogout()}
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={() => this.toggleModel()}
                    type="button"
                    className="text-black bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg  text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <Navbar className=" w-full  py-2 px-4  lg:px-8 lg:py-4 rounded-none bg-neutral-900 border-none">
          <div className="flex items-center justify-between text-white">
            <Typography
              as="a"
              href="#"
              variant="small"
              className="mr-4 cursor-pointer py-1.5 font-bold text-3xl"
            >
              <Link to="/"><img src={melody} className="w-10 h-10 rounded-full" alt="img" /> </Link>
            </Typography>
            <div className="hidden lg:block ">{navList}</div>

            {this.props.user?.username ?
              <div className="flex flex-col">
                <button
                  type="button" onClick={() => this.toggleDropDown()}
                  className=" hidden md:block  rounded-lg bg-cyan-500 px-4 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  <p>{this.props.user.username}
                  </p>
                </button>

                {this.state.isDropDownOpen ?
                  <div
                    id="dropdown"
                    className=" absolute mt-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li>
                        <Link
                          to="/dashboard"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link onClick={()=>{this.billingPortal()}}
                          
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
           f             >
                          Billing
                        </Link>
                      </li>
                      <li>
                        <p
                          onClick={() => { this.toggleModel() }}
                          className="apperance-none block px-4 py-2 cursor-pointer"
                        >
                          Logout
                        </p>
                      </li>
                    </ul>
                  </div>
                  : null
                }
              </div>
              :
              <button onClick={this.loginWithDiscord}
                type="button"
                className="hidden md:block rounded-lg bg-cyan-500 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
              >
                Login
              </button>
            }
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() =>
                this.setState({ isNavBarOpen: !this.state.isNavBarOpen })
              }
            >
              {this.state.isNavBarOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>














          <MobileNav open={this.state.isNavBarOpen}>
            <div className="mx-auto">
              {navList}
              {this.props.user?.username ?
                <button 
                  type="button"
                  className="inline-block bg-cyan-500 rounded  px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  {this.props.user.username}
                </button>
              :
              <button onClick={()=>{this.loginWithDiscord()}}
              type="button"
              className="inline-block rounded bg-cyan-500 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
            >
              Login
            </button>
       

  }
            </div>
          </MobileNav>
        </Navbar>
      </>
    );
  }
}

function mapStateToProps(state) {

  return {
    user: state.user
  };
}



export default connect(mapStateToProps)(NavBar);