import {BsFillPlayFill} from "react-icons/bs"


function Song(props) {
    const {title,author,image} = props.data
    return (
        <>
            <div className="bg-gray-200 rounded-lg p-5  group">
                <div className="relative pt-full mb-4">
                    <img
                        className="block w-full absolute inset-0"
                        src={image}
                        alt=""
                    />
                </div>
                <div className="text-sm text-white text-line-clamp-1 mb-1 block">
                    {title}
                </div>
                <div className="relative pb-5">
                    <span className="text-xs text-gray-100 text-line-clamp-1">
                        {author}
                    </span>
                    <button onClick={()=>{props.playTrack({title,author,image})}} className="absolute  items-center right-0 top-0 w-10 h-10 hidden group-hover:block  bg-green-200 rounded-full text-white">
                    
                        <BsFillPlayFill size={40} color="black" className="p-2"/>
                    </button>
                </div>
            </div>

        </>
    )
}

export default Song