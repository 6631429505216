function noVoice(){
    return (
        <div
        tabIndex={-1}
        className="  inset-0 bg-red-50  h-screen w-screen  flex bg-opacity-50 justify-center z-50 fixed pt-[200px]  top-0 right-0 left-0 "
    >
        <div className="relative w-full max-w-md h-full md:h-auto">
            <div className="relative bg-neutral-900 rounded-lg shadow ">
                <div className="p-6 text-center">
                    <h3 className="mb-5 text-lg font-normal text-white">
                    Please go to discord and join the voice channel. </h3>
                  </div>
            </div>
        </div>
    </div>
)
}

export default noVoice;