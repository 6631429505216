import React from "react"
import NavBar from "../layout/NavBar"
import fail from "../../assets/fail.png"
import { Link } from "react-router-dom"
class Fail extends React.Component {

render(){
return <>
<NavBar/>
<div className="bg-neutral-900 ">
  <div className=" p-6  md:mx-auto flex flex-col justify-center">
    <img className="h-[100px] w-[100px] mx-auto block " src={fail} alt="fail"/>
    <div className="text-center">
      <h3 className="md:text-2xl text-base text-red-500 font-semibold text-center">
        Payment Failed!
      </h3>
      <p className="text-white my-2">
      "Oops! It looks like your payment was unsuccessful. Please check that you entered your payment information correctly    </p>
      <p className="text-white"> Have a great day!</p>
      <div className="py-10 text-center">
        <Link
          to="/premium"
          className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
        >
          Go To Premium Page
        </Link>
      </div>
    </div>
  </div>
</div>










</>

}
}

export default Fail