import React from 'react'
import { url } from '../../config'
import NavBar from '../layout/NavBar'



const Login = () => {
   function loginWithDiscord() {
        window.open(`${url}/auth`, "_self")
      }
    
    return (
        <>
        <NavBar/>
        <div className='bg-neutral-900 flex flex-col items-center h-screen justify-center'>
            <h1 className='text-xl text-red-500 mb-2'> Login is required to access this page!</h1>
            <button onClick={loginWithDiscord} class="text-white bg-cyan-500 hover:bg-cyan-800 focus:ring-4 focus:ring-cyan-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-cyan-600 dark:hover:bg-cyan-700 focus:outline-none dark:focus:ring-cyan-800">Login With Discord</button>




        </div>
        </>
    )
}

export default Login