import React from "react";
import TabBar from "./TabBar";
import { url } from "../../config";
import Login from "../utility/Login";
import { connect } from "react-redux";

class ServerAdmin extends React.Component{

    

    constructor(){
        super();
        this.state = {
                guild: {name:"",icon:""},
                premium: {},
            
        }
    }


    async componentDidMount(){
        let res = await fetch(`${url}/guilds/${window.location.pathname.split("/")[2]}/stats`, {
            method: "GET",
            credentials: "include",
            Accept: "application/json",
      
            "Content-Type": "application/json",
          },
          );
          if (res.status === 200) {
            const {guild,premium} = await res.json();
                this.setState({guild,premium});
  
            
          }
    }
    
    
    render(){
      if(!this.props.user) return <Login/>
        return <>
        <TabBar guild={this.state.guild}/>

        <div className="mt-[30px] ml-auto lg:ml-[350px] md:ml-[350px] md:mx-10  rounded-lg">
    

    <div className="flex flex-col justify-center items-center h-[100vh]">
    <div className=" relative flex flex-col rounded-[20px] max-w-[300px] border-2 border-cyan-500 shadow-lg shadow-cyan-500/50 bg-neutral-800 bg-clip-border shadow-3xl shadow-shadow-500  w-full !p-4 3xl:p-![18px] ">
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={this.state.guild.icon}
            className="mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full"
            alt=""
          />
        </div>
        <div className="mb-3 flex items-center justify-between px-1 md:items-start">
          <div className="mb-2">
            <p className="text-xl font-bold text-white"> {this.state.guild.name} </p>
          </div>
         </div>
        <div className="flex items-center justify-between md:items-center lg:justify-between ">
          <div className="flex">
           { this.state.premium?.status === true ?
                <button
                type="button"
                class="inline-block rounded bg-cyan-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                PREMIUM 
              </button>
            :
            <button
  type="button"
  class="inline-block rounded bg-cyan-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
  Upgrade to Premium
</button>
           }
           
           
            </div>
          </div>
      </div>
    </div>
    </div>


  </div>


        </>
    }
}


function mapStateToProps(state) {

  return {
    user: state.user,
    guilds: state.guilds
  };
}



export default connect(mapStateToProps)(ServerAdmin);