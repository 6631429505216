import { url } from "../config"
import { set } from "../store/userSlice"
import { setGuilds } from "../store/guildSlice";
import { setPlaylist } from "../store/playlistSlice";
export default async function validate(user) {


    if (!user.props.user) {

        let res = await fetch(`${url}/auth/@me`, {
            method: "GET",
            credentials: "include",
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
     );

        if (res.status === 200) {
            let data = await res.json();
            user.props.dispatch(set(data));
        }


    }

}



export async function fetchPlaylist(data){

    if(!data.props.playlist){
        let res = await fetch(`${url}/auth/playlists`, {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          );
          if (res.status === 200) {
            let x = await res.json();
            data.props.dispatch(setPlaylist(x))
          }
      


    }

}


export  async function fetchGuilds(user) {


    if (!user.props.guilds) {

        let res = await fetch(`${url}/auth/guilds`, {
            method: "GET",
            credentials: "include",
            Accept: "application/json",

            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
     );

        if (res.status === 200) {
            let data = await res.json();
            user.props.dispatch(setGuilds(data));
           }


    }



    



}