import { createSlice } from '@reduxjs/toolkit';

const playlistSlice = createSlice({
    name: 'playlist',
    initialState:[],
    reducers: {
      setPlaylist(state, action) {
         state = action.payload
         return state
        },
    },
});

export const { setPlaylist } = playlistSlice.actions;
export default playlistSlice.reducer;
