import { createSlice } from '@reduxjs/toolkit';

const musicSlice = createSlice({
    name: 'music',
    initialState: {
        isConnected: true,
        track: null,
        queue: [],
        isPaused: false,
        loop: false,
        volume: 100,
        position: 0,
        interval: null

    },
    reducers: {
        setConnection(state, action) {
            state.isConnected = action.payload;
            return state
        },
         setTrack(state, action) {
            state.track = action.payload
            return state
        },
        setQueue(state, action) {
            state.queue = action.payload
            return state
        },
        setLoop(state, action) {
            state.loop = action.payload
            return state
        },
        setPaused(state, action) {
            state.isPaused = action.payload
            return state
        },
        setVolume(state, action) {
            state.volume = action.payload
            return state
        },
        setPosition(state, action) {
            state.position = action.payload
            return state;
        },
        setTime(state, action) {
            state.interval = action.payload
            return state
        },
        clearTime(state, action) {
            state.interval = null
            return state
        },

        setPlayer(state, action) {
            state.track = action.payload.track;
            state.queue = action.payload.queue;
            state.loop = action.payload.loop
            state.isPaused = action.payload.isPaused
            return state;

        }



    },
});

export const { setSocket, setConnection, setTrack, setQueue, setPosition, setTime, clearTime, setLoop, setVolume, setPaused, setPlayer } = musicSlice.actions;
export default musicSlice.reducer;