import { Link } from "react-router-dom"
import { footer,server } from "../../config"
import {RiDiscordFill} from "react-icons/ri"
function Footer(){
    return <>
    <footer className="bg-neutral-900 text-center text-white  lg:text-left">
      <div className="mx-6 py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {/* Tailwind Elements section */}
          <div className="">
            <h6 className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
                Melody Music
             </h6>
            <p>
            Experience music like never before with Melody Music bot!      </p>
          </div>
          {/* Products section */}
          <div className="">
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Melody
            </h6>
        {footer.melody.map((data)=>{
           return <p className="mb-4">
              <Link to={data.link} className="text-gray-300 ">
                {data.name}
              </Link>
            </p>
            })
            }
          </div>
          <div className="flex flex-col">
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Useful links
            </h6>

          {footer.links.map((data)=>{
            return <Link to={data.link} className="text-neutral-300 mb-4 ">
               {data.name}
              </Link>
            
            })
          }
          </div>
          {/* Contact section */}
          <div>
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Contact
            </h6>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-3 h-5 w-5"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
              support@melodybot.me
            </p>
            <Link to={server} className="mb-4 flex items-center justify-center md:justify-start gap-1">
             
             <RiDiscordFill size={25}/>
                Support Server
            </Link>
           </div>
        </div>
      </div>
      {/*Copyright section*/}
      <div className="bg-neutral-800 p-6 text-center font-bold">
        <span> Copyright © 2023 Melody Music  </span>
       </div>
    </footer>
  </>
  
}
export default Footer