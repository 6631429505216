export const info = {
    name: "Melody Music"
}
export let socket = null;
export const url = "https://api.melodybot.me";
export const ws = "https://api.melodybot.me"
export const CLIENT_ID = "776095696646438972"
export const REDIRECT_URL = "https://melodybot.me/dashboard"
export const INVITE_LINK = "https://discord.com/api/oauth2/authorize?client_id=776095696646438972&permissions=4298491984&redirect_uri=https%3A%2F%2Fdiscord.gg%2FtaAkCUxc&response_type=code&scope=bot%20applications.commands%20gdm.join"
export const ServerPremium = ['High Quality Music', 'Web Music Player', 'Custom Music Channel', '24/7 Music Playback', 'Music Radio', 'Multi-language support', 'Audio Filters', 'Custom Playlist', 'Volume Control', 'Vote Free Commands', 'Premium Support']
export const CustomBot = ['Custom Bot', 'Customizable Bot Name', 'Customizable Bot Avatar', 'Customizable Bot Avatar', 'Custom branding', 'High Quality Music', 'Multi-language support', 'All Features of Server Premium', 'Priority customer support', 'Advanced playlist management']
export const LifeTime = ['Higher quality audio streaming', 'Custom branding', 'Dedicated Support Team', 'Access to exclusive features']


export const bots = [
    {
        name: "Melody Music",
        avatar: "https://images-ext-2.discordapp.net/external/s0qkTuC1VTEDLTY7Ubt4ei0NbDatgke1webfuPg0cv4/%3Fsize%3D4096/https/cdn.discordapp.com/avatars/776095696646438972/820624b87b4b39ef273563fbe1814a19.png?width=427&height=427",
        link: "https://discord.com/api/oauth2/authorize?client_id=776095696646438972&permissions=58141038733649&redirect_uri=https%3A%2F%2Fdiscord.gg%2FtaAkCUxc&response_type=code&scope=bot%20applications.commands%20guilds.join"
    },
    {
        name: "Melody Music 2",
        avatar: 'https://images-ext-2.discordapp.net/external/ExkfG7T6iw5Nboax4LWB94NEdBd59azn1MGESmAi0So/%3Fsize%3D4096/https/cdn.discordapp.com/avatars/1047910761567105077/f804258c6f7eef08fcc7031e542af77e.png',
        link: "https://discord.com/api/oauth2/authorize?client_id=1047910761567105077&permissions=1071660785473&scope=bot%20applications.commands"
    },
    {
        name: "Melody Music 3",
        avatar: 'https://images-ext-1.discordapp.net/external/kLNDZHzfwSU8FWvIQ1BZKD0raEFv8hkcAEEpWcdkfN4/%3Fsize%3D4096/https/cdn.discordapp.com/avatars/1047910968971251792/2feb954e9ddf38b9164234683ed23388.png',
        link: "https://discord.com/api/oauth2/authorize?client_id=1047910968971251792&permissions=543379806529&scope=bot%20applications.commands"
    }
]








export const server = "https://discord.gg/sjJkKuyWVb"
export const footer = {



    melody: [
        {
            name: "Premium",
            link: "/premium"
        },
        {
            name: "Dashboard",
            link: "/dashboard"
        },
        {
            name: "Music Room",
            link: "/music-room"
        },
        {
            name: "Invite",
            link: "/invite"
        }
    ],
    links: [
        {
            name: "Terms of use",
            link: "/terms"
        },
        {
            name: "Privacy Policy",
            link: "/privacy"
        },
        {
            name: "upvote",
            link: "https://top.gg/bot/776095696646438972/vote"
        }

    ]


}





export const QuestionData = [
    {
        question: "Is my payment secure?",
        answer: `All transactions are performed through Stripe and are 100% secure.`
    }, {
        question: "How do I activate premium on my server?",
        answer: `You can activate premium on your server by using the +premium activate command`
    },
    {
        question: "How do I activate my custom bot?",
        answer: `To activate your custom bot on Discord,you need to join our support server and create premium support ticket`
    },
    {
        question: "Can I buy a premium plan that is customized to my needs?",
        answer: `Yes, you can subscribe to custom plans based on your usage. Please join our support server and create a premium support ticket to get more information.`
    }
]
