import React from "react"
import { connect } from "react-redux";
import validate, { fetchGuilds } from "../../utils/validate";
import NavBar from "../layout/NavBar";
import ServerBox from "./ServerBox";
import Footer from "../layout/Footer";
import Login from "../utility/Login";
class MusicRoom extends React.Component {


componentDidMount(){
    validate(this)
    fetchGuilds(this)
}
    render(){
        if(!this.props.user) return <Login/>
      
        return <>
         <>
                        <NavBar />
                        <div className="bg-neutral-900  flex justify-center items-center min-h-screen">


                            <div className="bg-zincwwwwwwwwwwwwwwwwwwwwwwwww-700 rounded-md p-9 md:w-[600px] w-[800px] mt-5">
                                <div>
                                    <h1 className="text-white text-2xl text-center">Hi,{this.props.user?.username} </h1>
                                    <p className="text-base font-bold lg:text-xl text-white mt-4 text-center">Please Select A Server To Get Started</p>
                                    <hr className="w-40 h-1 mx-auto my-4 bg-pink-700 border-0 rounded md:my-10 dark:bg-pink-700" />
                                </div>

                                <div>
                                    <p className="tracking-widest text-xl text-white">Showing {this.props.guilds?.userGuilds?.length + this.props.guilds?.mutualGuilds?.length} servers</p>
                                    <hr className="mt-4 h-1 bg-pink-400 border-0 rounded md:my-10 dark:bg-pink-700" />

                                </div>
                                <div className=" md:max-h-[300px] lg:max-h-[300px] lg:overflow-auto md:overflow:auto; ">


                                    {this.props.guilds?.mutualGuilds.map((guild) => {

                                        return <ServerBox key={guild.id} guild={guild} isMutual={true} />

                                    })
                                    }

                                    {this.props.guilds?.userGuilds.map((guild) => {

                                        return <ServerBox key={guild.id} guild={guild} isMutual={false} />

                                    })
                                    }



                                </div>




                            </div>

                        </div>
                    </>
        
                                    <Footer/>
        </>
    }
}

function mapStateToProps(state) {

    return {
        user: state.user,
        guilds: state.guilds
    };
}



export default connect(mapStateToProps)(MusicRoom);