import React from "react";
import discord from "../../assets/discord.jpg"
import {Link} from "react-router-dom"
export default class ServerBox extends React.Component {


    render() {
        const { guild, isMutual } = this.props;
        return <>

            <div className="flex  justify-between mt-8 md:mr-4 lg:mr-6">
                <div className="flex">
                    {guild.icon ?
                    <img src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}`} alt="" height="50" width="50" className="md:rounded-full rounded-full w-30 lg:h-[50px] h-[52px]" />

                    :
                    <img src={discord} alt="" height="50" width="50" className="md:rounded-full rounded-md w-30 lg:h-[50px] h-[52px]" />

    }
                    <p className="md:text-base ml-2 lg:ml-5 lg:text-xl text-white mt-2">{guild.name}</p>
                </div>
                <div>
                   
                   
                   {isMutual? <Link to={`/music-room/${guild.id}`} className="rounded-md r overflow-hidden text-white  text-sm lg:text-lg ml-[3px] lg:ml-3 bg-cyan-400 md:p-3 p-2">Go</Link>
                    :
                    <button className="rounded-md text-white  text-sm lg:text-lg ml-[3px] lg:ml-3 border-2 border-gray-100 p-3">setup </button>
    }
               
               
               
               </div>
            </div>


        </>
    }
}