import discord from "../../assets/discord.jpg"
import { Link } from "react-router-dom";
import {CLIENT_ID,REDIRECT_URL} from "../../config"
function Server(props) {
    const {name,icon,id}  = props.data
    return (
        <div class="p-4 md:w-1/3 sm:w-1/2 w-full">   <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden items-center flex flex-col">
        
{icon ?
<img alt="server logo" src={`https://cdn.discordapp.com/icons/${id}/${icon}`}  className="w-36 h-36 mt-4 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full "/>

                    :
    <img src={discord} alt="server logo"  className="w-36 h-36 mt-4  bg-gray-100 object-cover object-center flex-shrink-0 rounded-full " />

    }
            <h1 className="title-font text-lg font-medium text-white mb-3 mt-4">
              {name}
            </h1>
             <div className="flex items-center flex-wrap py-4">
        {props.mutual ?
            <Link to={`/dashboard/${id}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2">
  Manage
</Link>
:
<Link  to={`https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&guild_id=${id}&permissions=517610002753&redirect_uri=${REDIRECT_URL}&response_type=code&scope=bot%20guilds.join%20applications.commands`}className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2">
Setup
</Link>

}
          </div>
        </div>
      </div>
  
    )}
export default Server;