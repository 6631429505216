import image1 from "../../assets/image1.svg"
import headphone from "../../assets/headphone.svg"
import image2 from "../../assets/image2.svg"
import { BiHeadphone } from "react-icons/bi"
import { RxDashboard } from "react-icons/rx"
import {FiUserPlus} from "react-icons/fi"
import {FaUserFriends} from "react-icons/fa"
import { INVITE_LINK,server } from "../../config"
import {Link} from "react-router-dom"
function HomePage(props) {
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
              Melody Music
              <br className="hidden lg:inline-block" />
              The Perfect Discord Bot  </h1>
            <p className="mb-8 leading-relaxed text-white">
              Melody Music is a multi-purpose music bot ready to skill up and boost up your Discord server's voice activity. Includes features like filters, 24/7 and much more! </p>
            <div className="flex justify-center">
              <Link to ={INVITE_LINK} target="_blank" className="inline-flex text-white font-bold bg-cyan-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                Add To Discord
              </Link>
              <Link to={server} target="_blank" className="ml-4 inline-flex font-bold text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
                Ask For Help

              </Link>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              src={headphone} alt="headphone"
            />
          </div>
        </div>
      </section>



      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={image1} />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-cyan-500">
              Music dashboard
            </h1>
            <p className="mb-8 leading-relaxed text-white">
              Manage the music and your playlists directly from the website. Like songs, Add new songs,loop,shuffle and much more! </p>
          </div>
        </div>
      </section>


      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-cyan-400">
              Music Room
            </h1>
            <p className="mb-8 leading-relaxed text-white">
              Create your own music room with melody music and enjoy non-stop music with your friends!
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={image2}
            />
          </div>
        </div>
      </section>



      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="lg:w-1/2 lg:mb-0 mb-2 p-2/ bg-neutral-900 rounded-lg">

              <div class="mt-4 mr-auto mb-4 ml-auto bg-neutral-800 max-w-lg">
                <div class="flex flex-col items-center pt-6 pr-6 pb-6 pl-6">
                  <BiHeadphone size={50} color="cyan" />
                  <p class="mt-4 text-2xl font-semibold leading-none text-white tracking-tighter lg:text-3xl">24/7 MUSIC</p>
                  <p class="mt-3 text-base leading-relaxed text-center text-gray-200">Music that never sleeps, always just a command away!</p>
                </div>
              </div>
            </div>

            <div className="lg:w-1/2 lg:mb-0 mb-2 p-2/ bg-neutral-900 rounded-lg">

              <div class="mt-4 mr-auto mb-4 ml-auto bg-neutral-800 max-w-lg">
                <div class="flex flex-col items-center pt-6 pr-6 pb-6 pl-6">
                  <RxDashboard size={50} color="cyan" />
                  <p class="mt-4 text-2xl font-semibold leading-none text-white tracking-tighter lg:text-3xl">Music Web Player</p>
                  <p class="mt-3 text-base leading-relaxed text-center text-gray-200">Elevate your music experience with our powerful dashboard.</p>
                </div>
              </div>

            </div>



            <div className="lg:w-1/2 lg:mb-0 mb-2 p-2/ bg-neutral-900 rounded-lg">
              <div class="mt-4 mr-auto mb-4 ml-auto bg-neutral-800 max-w-lg">
                <div class="flex flex-col items-center pt-6 pr-6 pb-6 pl-6">
                  <FiUserPlus size={50} color="cyan" />
                  <p class="mt-4 text-2xl font-semibold leading-none text-white tracking-tighter lg:text-3xl">DJ Role System</p>
                  <p class="mt-3 text-base leading-relaxed text-center text-gray-200">Let our music bot with DJ role be your personal party starter!</p>
                </div>
              </div>
            </div>

            <div className="lg:w-1/2 lg:mb-0 mb-2 p-2/ bg-neutral-900 rounded-lg">
              <div class="mt-4 mr-auto mb-4 ml-auto bg-neutral-800 max-w-lg">
                <div class="flex flex-col items-center pt-6 pr-6 pb-6 pl-6">
                  <FaUserFriends size={50} color="cyan" />
                  <p class="mt-4 text-2xl font-semibold leading-none text-white tracking-tighter lg:text-3xl">
Play with your friends!</p>
                  <p class="mt-3 text-base leading-relaxed text-center text-gray-200">Bringing your favorite tunes to your Discord server - the ultimate music bot experience!</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>




      <section className="text-white body-font bg-neutral-800 mx-[100px] rounded-lg">
  <div className="container px-5 pt-10 ppb-6 mx-auto">
    <div className="flex flex-col items-center text-center w-full mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">
   Play high quality music in your Discord server for free.  </h1>
     <Link to ={INVITE_LINK} className="w-1/3 text-white font-bold mb-8 bg-cyan-500 border-0 py-2 px-6 focus:outline-none hover:bg-cyan-600 rounded text-lg">
                Add To Discord
              </Link>
             
    
    
     </div>
   </div>
</section>






















    </>


  )
}

export default HomePage