import React from "react";
import TabBar from "./TabBar";
import Song from "./Song";
import "../../Player.css"
import MusicPlayer from "./MusicPlayer";
import io from 'socket.io-client';
import { connect } from "react-redux";
import { url,ws } from "../../config";
import NoVoice from "./noVoice";
import NoPremium from "./noPremium"
import { setConnection, setLoop, setPaused, setPlayer, setPosition, setQueue, setTrack } from "../../store/musicSlice";
import validate from "../../utils/validate";
import {CiMenuFries} from "react-icons/ci"
import Login from "../utility/Login";
export let socket = null

class Player extends React.Component {

    constructor() {
        super();
        //   this.ws = null,
        this.state = {
            premium: true,
            mounted: false,
            isNavOpen:false
        }
    }

    toggleNavBar(){
        this.setState({isNavOpen:!this.state.isNavOpen})
    }

    async componentDidMount() {
        validate(this)


        let res = await fetch(`${url}/guilds/${window.location.pathname.split("/")[2]}/stats`, {
            method: "GET",
            credentials: "include",
            Accept: "application/json",

            "Content-Type": "application/json",
        },
        );
        if (res.status === 200) {
            const { premium } = await res.json();
            this.setState({ premium });
        }
        this.setState({ mounted: true })









        let connectionOptions = {
            "force new connection": true,
            "reconnectionAttempts": "Infinity",
            "timeout": 10000,
            withCredentials: true,
            extraHeaders: {
              "my-custom-header": "abcd"
            },
            "transports": ["websocket","polling"],
            query: { serverID: window.location.pathname.split("/")[2] }
        };
        this.ws = new io(ws,connectionOptions);
        socket = this.ws
        this.ws.on("disconnect", () => {
            console.log("disconnected");
        })
        this.ws?.on("connect", () => {
                  this.ws?.emit("PLAYER", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}` }));
        });

        this.ws?.on("PLAYER_UPDATE", (data) => {

            switch (data?.mode) {
                case "ALL":
                    {
                        const { track, queue, isPaused, loop } = data.player;
                        this.props.dispatch(setPlayer({ track, queue, isPaused, loop }))
                        break;
                    }
                case "RESET_PLAYER":
                    {
                        this.props.dispatch(setPlayer({ track: null, queue: [], isPaused: false, loop: false, volume: 100 }))
                        break;
                    }
                case "TRACK_START":
                    {
                        const { track } = data;
                        this.props.dispatch(setTrack(track))
                        this.props.dispatch(setPaused(false))
                        break;
                    }
                case "QUEUE_UPDATE":
                    {
                        const { queue } = data;
                        console.log(queue)

                        this.props.dispatch(setQueue(queue));
                        break;
                    }
                case "PLAYER_PAUSE":
                    {
                        this.props.dispatch(setPaused(data.isPaused));
                        break;
                    }
                case "PLAYER_LOOP":
                    {
                        this.props.dispatch(setLoop(data.loop));
                        break;
                    }
                case "PLAYER_POSITION":
                    {
                        this.props.dispatch(setPosition(data.position));
                        break;
                    }
                default:
                    {

                    }


            }




        })


        this.ws.on("VOICE_CONNECTION", (data) => {
            this.props.dispatch(setConnection(data));
        })

    }

    playTrack({ title, author, image }) {

        this.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "PLAY_TRACK", title, author, image }))


    }




    render() {
        if(!this.props.user) return <Login/>
        return <>

            {this.state.premium && !this.props.music.isConnected ?
                <NoVoice />

                : null}


            {!this.state.premium ?
                <NoPremium />
                :
                !this.props.music.isConnected && !this.state.premium ?
                    <NoVoice />
                    :
                    null
            }

            <div className="grid grid-rows-3 grid-cols-6 h-full spotify-circular">


                <TabBar toggle={this.state.isNavOpen} toggleNavBar={()=>{this.toggleNavBar()}} id={window.location.pathname.split("/")[2]} />
                <main className="col-span-5 row-span-3 overflow-auto">
                    <header className="px-6 py-4 mb-6 bg-neutral-900 flex items-center justify-between sticky top-0 z-10">
                        <div className="flex items-center">
                            <button onClick={()=>{this.toggleNavBar()}} className="h-8 w-8  lg:md:hidden  text-white flex">
                          
                            <CiMenuFries size={30} color="white"/>
                            </button>
                        </div>
                        <div>
                            <button className="text-xs text-white bg-gray-500 rounded-full p-px pr-3">
                                <img alt="avatar"
                                    src={this.props.user?.avatar}
                                    className="w-7 h-7 inline-block rounded-full mr-2"
                                />
                                {this.props.user?.username || "No User"}
                            </button>
                        </div>
                    </header>
                    <section className="px-6 grid gap-6 mb-8">
                        <div className="flex items-center">
                            <div className="flex-1">
                                <h3 className="text-2xl text-white">
                                    <p
                                        className=""
                                    >
                                        Upcoming Songs
                                    </p>
                                </h3>
                            </div>
                            <div>
                                <p
                                    className="text-xs text-gray-100 tracking-widest uppercase hover:underline"
                                >

                                </p>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-6 lg:md:gap-4 grid-cols-2 gap-4  items-center mb-[200px]">
                            {
                                this.props.music.queue.map((song, i) => {
                                    return <Song key={i} data={song} playTrack={(data) => { this.playTrack(data) }} />
                                })

                            }

                        </div>
                    </section>
                </main>
                <MusicPlayer ws={this.ws} />

            </div>





        </>
    }
}

function mapStateToProps(state) {

    return {
        user: state.user,
        music: state.music
    };
}



export default connect(mapStateToProps)(Player);
