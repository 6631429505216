import { configureStore } from '@reduxjs/toolkit';
import guildSlice from './guildSlice';
import userSlice from './userSlice';
import musicSlice from "./musicSlice"
import playlistSlice from './playlistSlice';
const store = configureStore({
    reducer: {
        user: userSlice,
        guilds:guildSlice,
        music:musicSlice,
        playlist:playlistSlice
    },
});

export default store;