import NavBar from "./layout/NavBar"
import { Link } from "react-router-dom"
import { bots } from "../config"
function Invite() {
    return <>
        <NavBar />
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-wrap -m-4">
                    {bots.map((data)=>{
                    return <div className="p-2 md:w-1/3 flex items-center justify-center ">
                        <div className="h-full  rounded-lg overflow-hidden">
                            <img
                                className="h-[200px]  w-[200px]  rounded-full "
                                src={data.avatar}
                                alt="avatar"
                            />
                            <div className="p-6">
                                <h1 className="font-bold text-lg  text-white  mb-3">
                                    {data.name}
                                </h1>
                                <Link
 to={data.link} target="_blank"
  class="inline-block rounded-full bg-cyan-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
  Invite
</Link>
                            </div>
                        </div>
                    </div>
                    })
                    }
                   
                </div>
            </div>
        </section>

    </>
}

export default Invite