import React from "react";
import TabBar from "./TabBar";
import { BsFillPlayFill } from "react-icons/bs"
import {AiOutlineInfoCircle} from "react-icons/ai"
import { connect } from "react-redux";
import MusicPlayer from "./MusicPlayer";
import validate from "../../utils/validate";
import { setPlaylist } from "../../store/playlistSlice";
import { url } from "../../config";
import { Link } from "react-router-dom";
import notify from "../../utils/notify";
import io from "socket.io-client"
import { setConnection, setLoop, setPaused, setPlayer, setPosition, setQueue, setTrack } from "../../store/musicSlice";
import SmallLoading from "../utility/SmallLoading";
import {CiMenuFries} from "react-icons/ci"
import Login from "../utility/Login";

class CustomPlaylist extends React.Component {

  constructor() {
    super();
    this.state = {
      toggle: false,
      deleteModel: false,
      delete:"",
      error: "",
      name: "",
      message: "",
      mounted:false,
      isNavOpen:false
       
    }
  }

  toggleNavBar(){
    this.setState({isNavOpen:!this.state.isNavOpen})
}
   

  async componentDidMount() {
    validate(this)


    let connectionOptions = {
      "force new connection": true,
      "reconnectionAttempts": "Infinity",
      "timeout": 10000,
      "transports": ["websocket"],
      query: { serverID: window.location.pathname.split("/")[2] }
  };
  this.ws = new io(url, connectionOptions);
  
  this.ws.on("disconnect", () => {
      console.log("disconnected");
  })
  this.ws?.on("connect", () => {
      this.ws?.emit("PLAYER", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}` }));
  });

  this.ws?.on("PLAYER_UPDATE", (data) => {

      switch (data?.mode) {
          case "ALL":
              {
                  const { track, queue, volume, isPaused, loop } = data.player;
                  this.props.dispatch(setPlayer({ track, queue, volume, isPaused, loop }))
                  break;
              }
          case "TRACK_START":
              {
                  const { track } = data;
                  this.props.dispatch(setTrack(track))
                  this.props.dispatch(setPaused(false))
                  break;
              }
          case "QUEUE_UPDATE":
              {
                  const { queue } = data;
                  console.log(queue)

                  this.props.dispatch(setQueue(queue));
                  break;
              }
          case "PLAYER_PAUSE":
              {
                  this.props.dispatch(setPaused(data.isPaused));
                  break;
              }
          case "PLAYER_LOOP":
              {
                  this.props.dispatch(setLoop(data.loop));
                  break;
              }
            case "PLAYER_POSITION":
                {
                    this.props.dispatch(setPosition(data.position));
                    break;
                }
                case "RESET_PLAYER":
                  {
                      this.props.dispatch(setPlayer({ track: null, queue: [], isPaused: false, loop: false, volume: 100 }))
                      break;
                  }
           
              default:
              {
                console.log(`[PLAYER] undexpected event recieved`)
              }

      }




  })


  this.ws.on("VOICE_CONNECTION", (data) => {
      this.props.dispatch(setConnection(data));
  })
  
















    let res = await fetch(`${url}/playlists`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    );
    this.setState({mounted:true})
    if (res.status === 200) {
      let x = await res.json();
      this.props.dispatch(setPlaylist(x))
    }




  }

  showModel() {
    this.setState({ toggle: true })
  }
  hideModel() {
    this.setState({ toggle: false, message: "", error: "" })
  }

  showDeleteModel(id) {
    this.setState({ deleteModel: true,delete:id })
  }

  hideDeleteModel() {
    this.setState({ deleteModel: false,delete:"" })
  }






  async handleSubmit(e) {
    e.preventDefault();
    if (!this.state.name) return;
    if (this.props.playlist.length >= 5) {
      return this.setState({ error: "You have reached the limit of 5 playlists." })
    }
    let res = await fetch(`${url}/playlists`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ name: this.state.name }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    );
    if (res.status === 200) {
      let x = await res.json();
      this.props.dispatch(setPlaylist(x))
      this.setState({ message: "Successfully Created!" })
    }


  }

  async playPlaylist(id){
let playlist= this.props.playlist.find(x => x._id ===id)

if(playlist && playlist.songs.length === 0){
  return notify(`Playlist is empty`,"error")
}

this.ws?.emit("PLAYER_ACTION", JSON.stringify({ server: `${window.location.pathname.split("/")[2]}`, action: "PLAYLIST_PLAY",id }))
notify("The playlist has been added to the queue.")
  }


  async handleDeletePlaylist(){

    let res = await fetch(`${url}/playlists/delete/${this.state.delete}`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    );
    if (res.status === 200) {
      let x = await res.json();
      this.props.dispatch(setPlaylist(x))
      notify("Successfully Deleted!" )
      this.hideDeleteModel();
    }

  }

  render() {
    if(!this.props.user) return <Login/>
      
    return (
      <>


        {this.state.deleteModel ? (
          <div
            id="popup-modal"
            tabIndex={-1}
            className="flex justify-center  mt-[150px] ml-auto lg:ml-[150px] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 p-4 md:inset-0 h-modal md:h-full"
          >
            <div className="relative w-full max-w-md h-full md:h-auto">
              <div className="relative bg-neutral-900 rounded-lg shadow ">
                <div className="p-6 text-center">
                  <AiOutlineInfoCircle
                    className="text-center m-auto"
                    style={{ fontSize: "50px", color: "white" }}
                  />
                  <h3 className="mb-5 text-lg font-normal text-white">
                    Are you sure you want to delete this playlist?
                  </h3>
                  <button
                    type="button"
                    onClick={() => this.handleDeletePlaylist()}
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={() => this.hideDeleteModel()}
                    type="button"
                    className="text-black bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg  text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}


        {this.state.toggle ? (
          <form onSubmit={(e) => { this.handleSubmit(e) }}
            id="popup-modal"
            tabIndex={-1}
            className="flex justify-center  mt-[150px] ml-auto lg:ml-[150px] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 p-4 md:inset-0 h-modal md:h-full"
          >
            <div className="relative w-full max-w-md h-full md:h-auto">
              <div className="relative bg-neutral-900 rounded-lg shadow ">
                <div className="p-6">
                  <h3 className="mb-5 text-lg font-normal text-white">
                    <div>
                      <label for="first_name" className="block mb-2 text-sm font-medium text-white justify-start">Playlist name</label>
                      <input name="name" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="example: my english songs" required />
                      <p className="text-lg text-red-500 "> {this.state.error} </p>
                      <p className="text-lg text-green-500 "> {this.state.message} </p>

                    </div>
                  </h3>
                  <button
                    type="submit"
                    className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Create
                  </button>
                  <button
                    onClick={() => this.hideModel()}
                    type="button"
                    className="text-black bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg  text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300  dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : null}





        <div className="grid grid-rows-3 grid-cols-6 h-full spotify-circular">

        <TabBar toggle={this.state.isNavOpen} toggleNavBar={()=>{this.toggleNavBar()}} id={window.location.pathname.split("/")[2]} />
             
          <main className="col-span-5 row-span-3 overflow-auto mt-8">
          <header className="px-6 py-4 mb-6 bg-neutral-900 flex items-center justify-between sticky top-0 z-10">
                        <div className="flex items-center">
                            <button onClick={()=>{this.toggleNavBar()}} className="h-8 w-8  lg:md:hidden  text-white flex">
                          
                            <CiMenuFries size={30} color="white"/>
                            </button>
                        </div>
                        <div>
                            <button className="text-xs text-white bg-gray-500 rounded-full p-px pr-3">
                                <img alt="avatar"
                                    src={this.props.user?.avatar}
                                    className="w-7 h-7 inline-block rounded-full mr-2"
                                />
                                {this.props.user?.username || "No User"}
                            </button>
                        </div>
                    </header>
                


            <div className="max-w-full mx-10 my-10 bg-neutral-800 p-8 rounded-xl shadow shadow-green-300">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <h1 className="text-3xl font-medium text-white lowercase">{this.props.user ? ` ${this.props.user?.username} 's playlists ` : ""}</h1>
                </div>
                <button onClick={() => { this.showModel() }} className="inline-flex space-x-2 items-center">
                  <p
                    className="p-2  rounded-md inline-flex space-x-1 items-center text-white hover:text-white bg-green-600 hover:bg-green-500"
                  >
                    <span className="text-sm font-medium hidden md:block">Create a new playlist</span>
                  </p>

                </button>
              </div>
              <p className="text-gray-200">Your Ultimate Mixtape.</p>
              <div id="tasks" className="my-5">

                {this.state.mounted ?
                <>
                {this.props.playlist.map((playlist) => {
                  return <div
                    key={playlist._id}
                    className=" group flex justify-between items-center border-b border-slate-200 py-3 px-2 border-l-4  border-l-transparent"
                  >
                    <Link to={`/music-room/${window.location.pathname.split("/")[2]}/custom/${playlist._id}`} className="inline-flex items-center space-x-2">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 text-white font-bold"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </div>
                      <div className="text-white">{playlist.name}</div>
                    </Link>


                    <div className="justify-end flex flex-row">

                      <button onClick={() => {this.playPlaylist(playlist._id)  }} className="w-10 h-10   bg-green-200 rounded-full text-white">

                        <BsFillPlayFill size={40} color="black" className="p-2" />
                      </button>



                      <button onClick={()=>{this.showDeleteModel(playlist._id)}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 text-red-500 hover:text-red-700 hover:cursor-pointer ml-2 mt-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>

                    </div>



                  </div>
                })
              }
                </>
              :
              <SmallLoading/>
  }
  

              </div>
            </div>




          </main>



          <MusicPlayer ws={this.ws} />

        </div>

      </>
    )
  }






}


function mapStateToProps(state) {

  return {
    user: state.user,
    music: state.music,
    playlist: state.playlist
  };
}



export default connect(mapStateToProps)(CustomPlaylist);