import { Link } from "react-router-dom";
import {RiPlayListFill} from "react-icons/ri"
import {FiRadio} from "react-icons/fi"
import { server } from "../../config";
import {AiOutlineClose} from "react-icons/ai"
function TabBar(props) {
    return (
        <>
            <nav className={`${props.toggle ? ""  : "hidden"}   lg:block md:block w-[200px]  z-40 md:w-auto lg:w-auto lg:md:col-span-1 lg:md:row-span-3 sm:hidden bg-neutral-950 pt-6 rouned-lg`}>
                <div className="px-6 mb-6 flex flex-row ">
                <button onClick={()=>{props.toggleNavBar()}} className="top-1 ml-[140px]  lg:hidden md:hidden"> <AiOutlineClose color="white" size={30}/> </button>
                  <img alt={"icon"}className="h-12 w-12 rounded-full  md:lg:ml-auto ml-[-11.75rem]" src="https://github.com/parasop/resource/blob/main/melody.png?raw=true"/>
                    <h1 className="text-white text-bold text-xl   pt-2 lg:md:ml-1 ">Melody Music</h1>
                   </div>
                <div className="px-2 mb-6">
                    <Link  to={`/music-room/${props.id}`} className="bg-gray-200 flex font-medium items-center px-4 py-2 rounded text-sm text-white">
                        <svg
                            className="h-6 mr-4"
                            viewBox="0 0 512 512"
                            width={24}
                            height={24}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M448 463.746h-149.333v-149.333h-85.334v149.333h-149.333v-315.428l192-111.746 192 110.984v316.19z"
                                fill="currentColor"
                            />
                        </svg>
                        Home
                    </Link>
                    <Link  to={`/music-room/${props.id}/search`} className="flex font-medium items-center px-4 py-2 rounded text-sm text-gray-100 hover:text-white">
                        <svg
                            className="h-6 mr-4"
                            viewBox="0 0 512 512"
                            width={24}
                            height={24}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M349.714 347.937l93.714 109.969-16.254 13.969-93.969-109.969q-48.508 36.825-109.207 36.825-36.826 0-70.476-14.349t-57.905-38.603-38.603-57.905-14.349-70.476 14.349-70.476 38.603-57.905 57.905-38.603 70.476-14.349 70.476 14.349 57.905 38.603 38.603 57.905 14.349 70.476q0 37.841-14.73 71.619t-40.889 58.921zM224 377.397q43.428 0 80.254-21.461t58.286-58.286 21.461-80.254-21.461-80.254-58.286-58.285-80.254-21.46-80.254 21.46-58.285 58.285-21.46 80.254 21.46 80.254 58.285 58.286 80.254 21.461z"
                                fill="currentColor"
                                fillRule="evenodd"
                            />
                        </svg>
                        Search
                    </Link>
                    <div className="flex font-medium items-center px-4 py-2 rounded text-sm text-gray-100 hover:text-white">
                            <FiRadio size={40} className="pr-2"/>
                        Radio (soon)
                    </div>
                </div>
                <div>
                    <h3 className="text-xs  uppercase text-white px-6 tracking-widest font-light mb-4">
                        Playlists
                    </h3>
                    <Link to={`/music-room/${props.id}/custom`} className="mb-3">
                        <div className="px-6 py-1 flex items-center text-sm text-white opacity-50 hover:opacity-100">
                        <RiPlayListFill size={40} className="p-2"/>
                            Custom Playlist
                        </div>
                        </Link>
                    <hr className="border-gray-200 mx-6 mb-3" />
                    <div>
                        <Link to={server} className="px-6 py-2 flex items-center text-sm  text-gray-100 hover:text-white">
                            Join Discord Server
                        </Link>
                        <Link to={server} className="px-6 py-2 flex items-center text-sm  text-gray-100 hover:text-white">
                            Suggestion
                        </Link>
                    </div>
                </div>
            </nav>



        </>


    )

}

export default TabBar;